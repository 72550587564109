* {
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
	border: none;
	outline: none;
	box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, p, a, button, input, span {
    font-family: $font-type;
}

.svg-dos-capas{
    .cls-1{
        fill: $dark !important;
    }
    .cls-2{
        fill: $primary !important;
    }
}

.svg-una-capa > path {
	fill: $primary !important;
	g{
		fill: $primary !important;
	}
}

.svg-una-capa {
	g > path{
		fill: $primary !important;
	}
}

@media only screen and (min-width: $device-sm) {

}

@media only screen and (min-width: $device-md) {

}

@media only screen and (min-width: $device-lg) {

}

@media only screen and (min-width: $device-xl) {

}